<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
          class="wizard wizard-2"
          id="kt_wizard_v2"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12">
                <h4>Manage faculty teachers</h4>
                <router-link :to="{ name: 'dashboard' }">
                  Dashboard
                </router-link>
                \
                <router-link :to="{ name: 'dashboard' }">
                  Faculty members
                </router-link>
                \
                Faculty
              </div>
              <!--                        <div class="col-4">-->
              <!--                            <h4>Manage Institution</h4>-->
              <!--                        </div>-->
              <div class="col-md-6 col-lg-6 col-sm-12 text-right">
                <button @click="openDialog()" class="btn btn-primary ml-2">

                  Add faculty teacher
                </button>
                <button @click="openDialog()" class="btn btn-standard ml-2">

                  Back
                </button>
              </div>
              <div class="col-12">
                <div class="">
                  <table class="table table-stripe">
                    <thead>
                      <th>Title</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Designation</th>
                      <!-- <th>Active</th> -->
                      <th>Action</th>
                    </thead>
                    <tbody>
                      <tr v-for="(teacher, index) of teachers" :key="index">
                        <td>{{teacher.full_name}}</td>
                        <td>{{teacher.email}}</td>
                        <td>{{teacher.phone}}</td>
                        <td>{{teacher.designation}}</td>
                        <!-- <td>
                          <i
                            class="fas fa-circle"
                            :class="teacher.is_active?'text-success':'text-danger'"
                          ></i>
                        </td>-->
                        <td>
<!--                        <span class="cursor-pointer" @click="openDialog(teacher.id)">-->
<!--                            <i class="fas fa-edit"></i>-->
<!--                          </span>-->
                          <span class="cursor-pointer ml-3" @click="deleteTeacher(teacher.id)">
                            <i class="fas fa-trash"></i>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row" v-if="teachers.length>0">
                <div class="col-12 text-center">
                  <b-pagination
                    @input="getTeachers"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                  ></b-pagination>
                  <!--  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <create-and-update ref="teacher" :faculty_id="faculty_id" @close_dialog="closeDialog"></create-and-update>
    </div>
  </v-app>
</template>
<script>
import FacultyTeacherService from "@/core/services/faculty/teacher/FacultyTeacherService";

import CreateAndUpdate from "./CreateAndUpdate";

const teacherService = new FacultyTeacherService();

export default {
  name: "TeacherFaculty",
  components: {
    CreateAndUpdate
  },
  data() {
    return {
      teachers: [],
      teacher_id: null,
      page: null,
      total: null,
      perPage: null
    };
  },
  computed: {
    faculty_id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getTeachers();
  },
  methods: {
    getTeachers() {
      teacherService.paginate(this.faculty_id).then(response => {
        this.teachers = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    },
    openDialog(id) {
      this.teacher_id = id;
      this.$refs["teacher"].showModal(id);
    },
    closeDialog(id) {
      this.teacher_id = id;
      this.$refs["teacher"].hideModal();
      this.getTeachers();
    },
    deleteTeacher(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            teacherService.delete(this.faculty_id, id).then(response => {
              this.$snotify.success("Information deleted");
              this.getTeachers();
            });
          }
        }
      });
    }
  }
};
</script>
