<template>
  <div>
    <b-modal id="modal-scrollable" size="xl" ref="my-modal" hide-footer title="Faculty">
      <div class="d-block text-left"></div>
      <div class="row" v-for="(teacher, i) of teachers" :key="i" :class="i>1?'mt-3':null">
        <div class="col-6">
          <label for="faculty">Faculty Fullname</label>
          <b-input id="faculty" class="border border-dark" v-model="teacher.full_name"></b-input>
          <span
            class="text-danger"
            v-if="$v.teachers.$each[i].full_name.$error"
          >Fullname is required</span>
        </div>
        <div class="col-6">
          <label for="t_email">Faculty Email</label>
          <b-input id="t_email" class="border border-dark" v-model="teacher.email"></b-input>
          <span class="text-danger" v-if="$v.teachers.$each[i].email.$error">Email must be valid</span>
        </div>
        <div class="col-6">
          <label for="phone">Faculty Phone</label>
          <b-input id="phone" class="border border-dark" v-model="teacher.phone"></b-input>
          <span class="text-danger" v-if="$v.teachers.$each[i].phone.$error">Phone must be valid</span>
        </div>
        <div class="col-6">
          <label for="faculty_address">Faculty Address</label>
          <b-input id="faculty_address" class="border border-dark" v-model="teacher.address"></b-input>
        </div>
        <div class="col-6">
          <label for="faculty_id">Faculty ID</label>
          <b-input id="faculty_id" class="border border-dark" v-model="teacher.staff_id"></b-input>
        </div>
        <div class="col-6">
          <label for="faculty">Faculty Designation</label>
          <b-input id="faculty" class="border border-dark" v-model="teacher.designation"></b-input>
        </div>

        <div class="col-3 mt-5">
          <b-form-checkbox v-model="teacher.is_active" name="check-button" class switch>Status</b-form-checkbox>
        </div>
        <div class="col-3 mt-5 text-right">
          <span v-if="teachers.length>1" @click="removeTeacher(i)" class="btn btn-sm btn-primary">
            <i class="fas fa-trash"></i>
          </span>
          <span @click="addTeacher" class="btn btn-sm btn-primary ml-3">
         <i class="fas fa-add"></i>
          </span>
        </div>
        <hr />
      </div>
      <div class="row">
        <div class="col-12 text-right mt-4">
          <button class="btn btn-primary" @click="createAndUpdate">Save</button>
          <button class="btn btn-standard  ml-2" @click="hideModal">Cancel</button>

        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { required, numeric, email, minLength } from "vuelidate/lib/validators";
import FacultyTeacherService from "@/core/services/faculty/teacher/FacultyTeacherService";
const teacherService = new FacultyTeacherService();

export default {
  props: ["faculty_id"],
  name: "faculty-create-and-update",
  validations: {
    teachers: {
      $each: {
        full_name: { required },
        email: { required, email },
        phone: { required, numeric, minLength: minLength(10) }
      }
    }
  },
  data() {
    return {
      edit: false,
      teachers: [
        {
          full_name: null,
          email: null,
          phone: null,
          is_active: true
        }
      ]
    };
  },
  mounted() {
    // console.log(this.$v);
  },
  methods: {
    showModal(id = null) {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.teacher = [];
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    addTeacher() {
      this.teachers.push({
        full_name: null,
        email: null,
        phone: null,
        is_active: true
      });
    },
    removeTeacher(i) {
      this.teachers.splice(i, 1);
    },
    createAndUpdate() {
      this.$v.$touch();
      if (this.$v.teachers.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.updateFaculty();
        } else {
          this.createFaculty();
        }
      }
    },
    createFaculty() {
      teacherService.store(this.faculty_id, this.teachers).then(response => {
        this.resetTeacher();
        this.$emit("close_dialog");
      });
    },
    updateFaculty() {
      teacherService.update(this.faculty.id, this.faculty).then(response => {
        this.resetTeacher();
        this.$emit("close_dialog");
      });
    },
    resetTeacher() {
      this.teachers = [
        {
          full_name: null,
          email: null,
          phone: null,
          is_active: true
        }
      ];
      this.$v.$reset();
    }
  }
};
</script>
